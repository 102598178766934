/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, {useState, useRef, useEffect} from 'react';
import useGetMouse from '../../hooks/use-get-mouse';
import {map} from '../../util/map';
import { 
  useGLTF, 
  useAnimations, 
  GradientTexture
} from '@react-three/drei';

function resize(setWidth) {
  setWidth(window.innerWidth);
}

export default function Model({ ...props }) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF('/daniel.gltf');
  const { actions } = useAnimations(animations, group);
  const [x, y] = useGetMouse();
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    window.addEventListener('resize', () => resize(setWidth));
    return () => {
      window.removeEventListener('resize', () => resize(setWidth));
    };
  }, []);

  return (
    <group ref={group} {...props} dispose={null}>
      <group>
        <mesh 
          name="Curve" 
          geometry={nodes.Curve.geometry} 
          material={materials.SVGMat} 
          scale={map(window.innerWidth, 0, 1440, 20, 40)}
          rotation={[10.8, 9.43, map(x, 0, window.innerWidth, 0, 3.19)]}
        >
          {/* <meshLambertMaterial attach="material" color="#23252B" /> */}
          <meshBasicMaterial>
            <GradientTexture
              stops={[0, 1]} // As many stops as you want
              colors={['#393C46', '#727683']} // Colors need to match the number of stops
              size={1024} // Size is optional, default = 1024
            />
          </meshBasicMaterial>
          
        </mesh>
      </group>
    </group>
  )
}

useGLTF.preload('/daniel.gltf')
